.centering {
  margin: 0 auto;
}

.wcentering {
  max-width: 400px;
}

.rcentering {
  max-width: 600px;
  margin: 0 auto;
}

.centerios {
  text-align: center;
}

img {
  max-width: 100%;
}

.start-img {
  height: auto;
}

.impress {
  cursor: pointer;
}

/* .qr-image {
  width: 40%;
  float: left;
}
.clear-text {
  clear: left;
}
.float-text {
  vertical-align: middle;
} */

.flex-thing {
  display: flex;
  justify-content: center;
  padding-right: 5px;
}

.reihe {
  display: flex;
  flex-direction: row;
  justify-content: start;
}

.viz {
  visibility: hidden;
}

.qr-image {
  width: 40%;
  height: 40%;
}

.kursiv {
  font-style: italic;
}

.margin-top {
  margin-top: 12px;
}

.hinwifarbe {
  color: #282c34;
  font-size: 0.85em;
}

.bckr {
  background-color: #f3eac2;
}

.rbckr {
  background-color: #899857;
}

.thumb {
  width: 50%;
}

.strr {
  text-align: left;
}

.inputb {
  border: 2px solid #9ad3bc;
  border-radius: 5px;
  color: #555;
  padding: 8px;
}

.importbutton {
  margin-top: 10px;
  padding: 8px 12px;
  background: #9ad3bc;
  border: 0;
  font-weight: bold;
  font-family: inherit;
  cursor: pointer;
  border-radius: 7px !important;
  color: #555;
}

.luecke {
  height: 100px;
}

.antword {
  color: #555;
  font-weight: bold;
  font-size: 0.85em;
}

.valii {
  font-size: 0.85em;
  color: #ec524b;
}

.anlei {
  font-size: 0.79em;
}

.butto {
  margin-top: 10px;
  padding: 8px 12px;
  background: #ffc000;
  border: 0;
  font-weight: bold;
  font-family: inherit;
  cursor: pointer;
  border-radius: 7px !important;
  color: #538135;
}

.valida {
  color: #f37121;
  font-weight: bold;
  font-size: 0.75em;
}

.inputb {
  border: 2px solid #538135;
  border-radius: 5px;
  color: #538135;
  padding: 8px;
}

.txthellg {
  color: #ffe75e;
}

.txtdunkelg {
  color: #feb72b;
}

.txtdunkelgr {
  color: #527318;
}

.aadresse {
  font-size: 0.7em;
}

.aunderline {
  text-decoration: underline;
}

.abold {
  font-weight: bold;
}

.abstandl {
  margin-bottom: 40px;
}

.abstandr {
  margin-right: 10px;
}

.abstando {
  margin-top: 60px;
}

.pointer {
  cursor: pointer;
}

.abstandu {
  height: 280px;
}

.abstanduklein {
  height: 40px;
}

.abstandbar {
  height: 40px;
}

.texteins {
  font-size: 0.7em;
}

.txtkleiner {
  font-size: 0.85em;
}

.marginround {
  margin-left: 16px;
  margin-right: 16px;
}
